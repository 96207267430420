import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

function App() {
  const [searchFile, setSearchFile] = useState(null);
  const [indexFile, setIndexFile] = useState('');
  const [result, setResult] = useState('');
  const [dockerMode, setDockerMode] = useState(true);
  const [logs, setLogs] = useState('');
  const textareaRef = useRef(null);

  useEffect(() => {
    // Scroll to the bottom of the textarea
    textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
  }, [logs]);

  const compressImage = (file) => {
    const img = new Image();
    const reader = new FileReader();

    reader.onload = function (event) {
      img.src = event.target.result;
      img.onload = function () {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const aspectRatio = img.width / img.height;
        const newHeight = 800;
        const newWidth = aspectRatio * newHeight;

        // Set canvas dimensions
        canvas.width = newWidth;
        canvas.height = newHeight;

        // Draw the image to the canvas
        ctx.drawImage(img, 0, 0, newWidth, newHeight);

        // Convert canvas back to a blob
        canvas.toBlob((blob) => {
          const compressedFile = new File([blob], file.name, { type: file.type });
          // Update with the compressed file
          setSearchFile(compressedFile);
          addLog('Image added ' + compressedFile.name + ' @ ' + Math.floor(compressedFile.size / 1024) + 'KB');
        }, file.type);
      };
    };

    reader.readAsDataURL(file);
  };


  const handlePaste = (e) => {
    e.preventDefault();

    // Check if clipboard contains files
    const items = e.clipboardData?.items; // Use optional chaining to avoid undefined errors

    if (items) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].kind === 'file') {
          const file = items[i].getAsFile();

          // Reuse the image compression logic
          compressImage(file);

          document.getElementById('searchFile').files = e.clipboardData.files;
          addLog('Image pasted from clipboard');
          break;
        }
      }
    } else {
      console.warn('No files in clipboard');
    }
  };


  const handleSearchFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Reuse the image compression logic
    compressImage(file);
  };



  const handleIndexFileChange = (e) => {
    setIndexFile(e.target.value);
    addLog('Index file selected ' + e.target.value);
  };




  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('search_file', searchFile);
    formData.append('index_file', indexFile);
    const url = dockerMode ? 'https://ns.h0.work:51761/retrieve' : 'http://localhost:5000/retrieve';
    addLog('Uploading image to server...');
    try {
      const response = await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Response:', response.data.results);
      addLog('Results received');
      setResult(response.data.results);
    } catch (error) {
      console.error('Error uploading image:', error);
      addLog('Error uploading image: ' + error.message);
    }
  };

  const handleSwitchChange = () => {
    setDockerMode(!dockerMode);
    addLog('Docker Mode is ' + (!dockerMode ? 'ON' : 'OFF'));
    console.log('Docker Mode is ' + (!dockerMode ? 'ON' : 'OFF'));
  };

  const addLog = (log) => {
    setLogs(logs + log + '\n');
  }




  return (
    <div className="App">
      <div className="container">
        <header className="d-flex flex-wrap justify-content-between align-items-center py-3 border-bottom">
          <a href="." className="d-flex align-items-center text-dark text-decoration-none">
            <span className="fs-4">DINOv2 Demo: Retrieving anime scenes from real photos</span>
          </a>

          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="dockerModeSwitch"
              checked={dockerMode}
              onChange={handleSwitchChange}
            />
            <label className="form-check-label" htmlFor="dockerModeSwitch">{dockerMode ? <div><i class="bi bi-cloud-fill"></i> Remote Docker</div> : <div><i class="bi bi-bug-fill"></i> Local Debug</div>}</label>
          </div>
        </header>

        <div className='container mt-4'>
          <div className='row'>
            <div className='col-md-8 text-start'>
              <form onSubmit={handleSubmit}>
                <div>
                  <div className='mb-3'>
                    <label htmlFor='searchFile' className='form-label'>Upload Search File:</label>
                    <input
                      type='file'
                      className='form-control'
                      id='searchFile'
                      onChange={handleSearchFileChange}
                      required
                    />
                  </div>

                  <textarea
                    placeholder='You may also paste the image here'
                    onPaste={handlePaste}
                    style={{
                      width: '100%',
                      height: '150px',
                      borderRadius: '8px',
                      border: '1px solid #ced4da',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                      padding: '12px',
                      fontSize: '16px',
                      resize: 'none', // Prevent resizing
                      outline: 'none',
                      backgroundColor: '#f8f9fa',
                    }}
                  />
                </div>


                <div className="mb-3 text-start">
                  <label htmlFor="indexFile" className="form-label">Select Index File:</label>
                  <select
                    className="form-select"
                    id="indexFile"
                    value={indexFile}
                    onChange={handleIndexFileChange}
                    required
                  >
                    <option value="">Select a library</option>
                    <option value="./data/228350.bin">BANANA FISH (228350)</option>
                    <option value="./data/277518.bin">Given (277518)</option>
                    <option value="./data/27364.bin">Hyouka (27364)</option>
                    <option value="./data/1424.bin">K-ON (1424)</option>
                    <option value="./data/464376.bin">Makeinu (464376)</option>
                    <option value="./data/160209.bin">Your Name. (160209)</option>
                    <option value="./data/207195.bin">Yuru Camp (207195)</option>
                    <option value="./data/262897.bin">Yuru Camp S2 (262897)</option>
                  </select>
                </div>


                <button type='submit' className='btn btn-primary'>
                  Submit
                </button>
              </form>
            </div>

            {/* uploaed image preview */}
            <div className='col-md-4'>
              <div className='row text-start'>
                {/* display logs */}
                <h3>Logs</h3>
                <textarea
                  value={logs}
                  ref={textareaRef}
                  style={{
                    width: '100%',
                    height: '200px',
                    borderRadius: '8px',
                    border: '1px solid #ced4da',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    padding: '12px',
                    fontSize: '16px',
                    resize: 'none', // Prevent resizing
                    outline: 'none',
                    backgroundColor: '#f8f9fa',
                  }}
                />
              </div>
              <div className='row text-start mt-3'>
                {searchFile && (
                  <div>
                    <h3>Uploaded</h3>
                    <img src={URL.createObjectURL(searchFile)} alt='Search File' style={{ maxWidth: '200px', maxHeight: '200px' }} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>


        <div className='row mt-3'>


          <div className='col'>
            {/* display the 5 selected images */}
            {result.length > 0 && (
              <div className='row text-start'>
                <h3>Retrieved results</h3>
                {result.map((path, index) => (
                  <img src={`/Screenshot_lib${path}`} alt={`Result ${index}`} style={{ maxWidth: '200px', maxHeight: '200px' }} />
                ))}
              </div>
            )}
          </div>

        </div>




        <footer class="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
          <div class="col-9 d-flex align-items-center">
            <span class="mb-md-0 text-muted">
              ©
              <a href="https://github.com/ihkk" class="link text-muted" target="_blank" style={{ textDecoration: "none" }}>Kai HE</a>            </span>
          </div>

        </footer>
      </div>
    </div>
  );
}

export default App;
